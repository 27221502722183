.layout.push {
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  margin: 12px;
  padding: 12px;
  border-radius: 2px;
  box-shadow: 0 0 3px rgba(0, 0, 0,.3);
}

.layout.stats {
  display: flex;
  flex-direction: column;
  margin: 12px;
  padding: 12px;
  .push--stats__container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    > .push--stat {
      background-color: #FFF;
      padding: 12px;
      box-shadow: 0 0 3px rgba(0, 0, 0,.3);
      border-radius: 2px;
      flex: 1;
      flex-direction: column;
      & + div {
        margin-left: 12px;
      }
    }
  }
}
